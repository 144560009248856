<template>
  <div>
    <v-dialog fullscreen persistent no-click-animation hide-overlay transition="dialog-bottom-transition"
      v-model="dialog">
      <v-card>
        <BarTitleDialog :title="title" @close="close" />
        <v-card-actions>
          <v-row class="">
            <v-col class="text-right">
              <v-btn color="blue darken-1" x-large class="mr-2" text @click="close">
                Fechar
              </v-btn>
              <v-btn :loading="loading" x-large color="primary" @click="save">
                Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="editedItem.description" outlined label="Nome do evento"
                  :error="errors.description.error" :error-messages="errors.description.message">
                  ></v-text-field>
              </v-col>
              <v-col cols="3">
                <CommonAreaSelect v-model="common_area" :errors="errors.common_area.message"></CommonAreaSelect>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="editedItem.n_pax" outlined label="Número Pessoas" type="number"
                  :error="errors.n_pax.error" :errorMessages="errors.n_pax.message"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <AutocompleteSearchCompany v-model="company" :errors="errors.company.message" />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="editedItem.resquester" class="ml-4" outlined label="Solicitante"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Início" type="datetime-local" v-model="editedItem.start_time"
                  :error="errors.start_time.error" :error-messages="errors.start_time.message" outlined></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="Termino" type="datetime-local" v-model="editedItem.end_time"
                  :error="errors.end_time.error" :error-messages="errors.end_time.message" outlined></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-textarea v-model="editedItem.obs" rows="6" outlined label="Observação"> </v-textarea>
              </v-col>
            </v-row>
            <v-row class="" no-gutters>
              <v-col>
                <v-textarea v-model="editedItem.justify_cancel" rows="2" outlined label="Justificativa de Cancelamento">
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import {
  mdiClose,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiFileExportOutline,
  mdiEmailSyncOutline,
  mdiWhatsapp,
} from '@mdi/js'

import { mapActions, mapGetters, mapMutations } from 'vuex'

import BarTitleDialog from '@/components/BarTitleDialog.vue'
import InputDate from '@/components/InputDate.vue'
import AutocompleteSearchCompany from '@/components/AutocompleteSearchCompany.vue'
import CommonAreaSelect from "@/components/SelectArea.vue";
import { t } from '@/@core/libs/i18n/utils'

export default {
  components: {
    BarTitleDialog,
    InputDate,
    AutocompleteSearchCompany,
    CommonAreaSelect,
  },

  props: {
    value: {
      type: Boolean,
    },
    in_data: {
      type: Object,
    }, //recebe os dados do hospede
    title: {
      type: String,
    },
  },
  data: () => ({
    loadingResendVoucher: false,
    // END RESEND VOUCHER
    loading_not: false,
    dialogSave: false,
    dialogDelete: false,
    loadingRooms: false,
    dialog: false,
    loading: false,
    company: '',
    common_area: '',
    editedItem: {
      id: '',
      common_area_id: '',
      user_id: '',
      company_id: '',
      start_time: '',
      end_time: '',
      status: '',
      resquester: '',
      n_pax: '',
      description: '',
      obs: '',
    },
    defaultItem: {
      id: '',
      common_area_id: '',
      user_id: '',
      company_id: '',
      start_time: '',
      end_time: '',
      status: '',
      resquester: '',
      n_pax: '',
      description: '',
      obs: '',
    },
    errors: {
      company: {
        error: false,
        message: '',
      },
      start_time: {
        error: false,
        message: '',
      },
      end_time: {
        error: false,
        message: '',
      },
      description: {
        error: false,
        message: '',
      },
      common_area: {
        error: false,
        message: '',
      },
      n_pax: {
        error: false,
        message: '',
      },

    },

    options: {},
    tab: null,
    icons: {
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiClose,
      mdiFileExportOutline,
      mdiEmailSyncOutline,
      mdiWhatsapp
    },
  }),

  watch: {
    value(val) {
      this.dialog = val
      if (val) {
      }
    },
    dialog(val) {
      this.$emit('input', val)
      if (!val) {
        this.close()
      }
    },
    in_data(val) {
      this.editedItem = val
      this.common_area = val.common_area_id
      this.company = val.company 
    },

  },

  created() {
    this.inicialize()

  },

  computed: {
    ...mapGetters('select', ['getCommonArea']),

    

  },

  methods: {
    ...mapActions('events_reserve', ['store', 'update', 'isAvailable']),
    ...mapActions('select', ['loadCommonArea']),

    inicialize() {

    },

    close() {
      this.dialogSave = false
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {
          ...this.defaultItem,
        }
      })
    },
    formatDate(date) {
      // const date = this.date
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    getExportPdf() {
      this.exportPdf(this.editedItem.id)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          // console.log(response)
        })
    },
    save() {
      if (!this.validations()) return
      const payload = {
        'common_area_id': this.common_area,
        'end_time': this.editedItem.end_time,
        'start_time': this.editedItem.start_time,
        'id': this.editedItem.id,
      }

      this.isAvailable(payload)
        .then(response => {
         
          if (response.data.available) {
            this.confirmSave()
          } else {
            this.$swal({
              confirmButtonColor: '#16b1ff',
              position: "center",
              html: '<p>' + 'Salão indisponível para a data selecionada' + '</p>',
              icon: "error",
              title: "Atenção",
            });
          }
        })
        .catch()
        .finally(() => {
          this.loading = false
          this.loading_not = false
        })
    },
    confirmSave() {
            
      if (this.company) {
        this.editedItem.company_id = this.company.id
      }

      if (this.editedItem.id) {
        this.editedItem.common_area_id = this.common_area
        this.update(this.editedItem)
          .then(response => {
            const reserve = response.data
            this.close()
            this.$emit('out_data', reserve)
            this.$toast.success('Salvo com sucesso !')
          })
          .catch()
          .finally(() => {
            this.loading = false
            this.loading_not = false
          })
      } else {
        this.editedItem.common_area_id = this.common_area
        this.store(this.editedItem)
          .then(response => {
            const reserve = response.data
            this.close()
            this.$toast.success('Salvo com sucesso !')
            this.$emit('out_data', reserve)

          })
          .catch()
          .finally(() => {
            this.loading = false
            this.loading_not = false
          })
      }
    },
    validations() {
      let validate = true
      if (this.editedItem.description == '') {
        this.errors.description.error = true
        this.errors.description.message = 'Campo obrigatório'
        validate = false
      }

      if (this.editedItem.n_pax == '') {
        this.errors.n_pax.error = true
        this.errors.n_pax.message = 'Campo obrigatório'
        validate = false
      } else if (this.editedItem.n_pax <= 0) {
        this.errors.n_pax.error = true
        this.errors.n_pax.message = 'Campo temque ser maior que 0'
        validate = false
      }


      if (this.common_area == '' || this.common_area == null) {
        this.errors.common_area.error = true
        this.errors.common_area.message = 'Campo obrigatório'
        validate = false
      }
      if (this.company == '' || this.company == null) {
        this.errors.company.error = true
        this.errors.company.message = 'Campo obrigatório'
        validate = false
      }

      let dateStart = new Date(this.editedItem.start_time)
      let dateEnd = new Date(this.editedItem.end_time)

      if (this.editedItem.start_time == '') {
        this.errors.start_time.error = true
        this.errors.start_time.message = 'Campo obrigatório'
        validate = false
      }

      if (this.editedItem.end_time == '') {
        this.errors.end_time.error = true
        this.errors.end_time.message = 'Campo obrigatório'
        validate = false
      }

      if (this.editedItem.end_time != '' && this.editedItem.start_time != '' && dateStart > dateEnd) {
        this.errors.end_time.error = true
        this.errors.end_time.message = 'Data de término deve ser maior que a data de início'
        validate = false
      }
      return validate
    },
  },
}
</script>

<style scoped>
.card-tab {
  min-height: 420px;
  min-width: 736px;
}

.svg-icon {
  height: 52px;

}

.tool_table_fam {
  height: 50px;
}
</style>
