<template>
<v-select :error-messages="errors" outlined v-model="area" :loading="loading" :disabled="loading" label="Salão" :items="areas" item-text="description" item-value="id"></v-select>
</template>

<script>
import axios from '@/plugins/axios'
import {
    mapGetters,
    mapActions
} from 'vuex'
export default {

    components: {},
    props: {
        value: {},
        errors:{},
    },

    data: () => ({
        loading: false,
        area: '',
        areas: [],
    }),

    watch: {
        value(val) {
            this.area = val
        },
        area() {
            this.$emit('input', this.area)
            this.$emit('change')
        }

    },

    created() {
        this.loadCommonArea()
        this.areas = this.getCommonArea
        this.area = this.value
        
    },
    computed: {
        ...mapGetters('select', ['getCommonArea']),
    },
    methods: {
        ...mapActions('select', ['loadCommonArea']),
    }
}
</script>
